import React, { Component } from 'react';

import butter from '../butter-client'
import VeranstaltungsKarte from '../react-components/VeranstaltungsKarte';

import { Helmet } from "react-helmet";

class Veranstaltungen extends Component {
    state = {
        data: {
            fields: {
                veranstaltung: []
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'veranstaltungen')
        this.setState(resp.data)
    }
    render() {
        const termine = [];
        const { fields } = this.state.data
        var options = {
            year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
        };

        for (const [index, value] of fields.veranstaltung.entries()) {
            var dateV = new Date(value.datum);
            var dateF = dateV.toLocaleDateString("de-DE", options) + " Uhr";
            termine.push(
                <VeranstaltungsKarte
                    key={index}
                    title={value.titel}
                    date={dateF}
                    ort={value.ort}
                    image={value.bild}
                    text={value.text}
                    eintritt={value.eintritt}
                />
            )
        };
        return (
            <React.Fragment>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Veranstaltungen</title>
                    <meta name="description" content="Kommende Veranstaltungen in und um Hollen herum." />
                    <link rel="canonical" href="http://hollen.de/events" />
                </Helmet>
                <div className="rsc-seperator">
                    <div className="title">Veranstaltungen</div>
                    <div className="text">Was ist los in Uplengen?</div>
                </div>
                <div className="leeresPanel">
                

                <div className="linkbox-extern">
                    <a Style="text-decoration: none" rel="noopener noreferrer" target="_blank" href="https://www.touristik-uplengen.de/veranstaltungen.html">Veranstaltungskalender Uplengen</a>
                </div>
                <div className="rsc-seperator">
                    <div className="text">Was ist los in Hollen?</div>
                </div>
                
                {termine}
                </div>
            </React.Fragment>
        );
    }
}

export default Veranstaltungen;