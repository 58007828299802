import React, { Component } from 'react';

import NewsPreview from './NewsPreview';
import butter from '../butter-client'

class InstaFeed extends Component {
    state = {
        data: {
            fields: {
                news_artikel: []
            }
        }
    }
    async componentDidMount() {
        var userID = 53567056393;
        var count = 9;
        try {
            const response = await fetch(
                `https://www.instagram.com/graphql/query?query_id=17888483320059182&variables={"id":"${userID}","first":${count},"after":null}`,
                {
                    mode: 'no-cors'
                }
            );
            console.log(response);
            const { data } = await response.json();

            const photos = data.user.edge_owner_to_timeline_media.edges.map(
                ({ node }) => {
                    const { id } = node;
                    var caption = "";
                    console.log(node.edge_media_to_caption.edges);
                    if (node.edge_media_to_caption.edges[0] != null) {
                        caption = node.edge_media_to_caption.edges[0].node.text;
                    }
                    const thumbnail = node.thumbnail_resources.find(
                        thumbnail => thumbnail.config_width === 640
                    );
                    const { src, config_width: width, config_height: height } = thumbnail;
                    const url = `https://www.instagram.com/p/${node.shortcode}`;
                    var a = new Date(node.taken_at_timestamp * 1000);
                    var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
                    var year = a.getFullYear();
                    var month = months[a.getMonth()];
                    var date = a.getDate();
                    var hour = a.getHours();
                    var min = a.getMinutes();
                    var sec = a.getSeconds();
                    var time = year + '-' + month + '-' + date + 'T' + hour + ':' + min + ':' + sec;
                    time = a.toISOString();
                    time = time.substring(0, 19);
                    return {
                        titel: "",
                        "news_text": caption,
                        "bild": src,
                        "datum": time,
                        "autor": "Hollen - Instagram",
                        "url": url
                    }
                }
            );
            console.log(photos);
            this.setState({

                data: {
                    fields: {
                        news_artikel: photos
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }

    }
    render() {
        const artikel = [];
        const { fields } = this.state.data
        var options = {
            year: 'numeric', month: '2-digit', day: '2-digit'
        };

        for (const [index, value] of fields.news_artikel.entries()) {
            var dateV = new Date(value.datum);
            var dateF = dateV.toLocaleDateString("de-DE", options);
            const bilder = [
                value.bild
            ];
            artikel.push(
                <NewsPreview
                    key={index}
                    title={value.titel}
                    date={dateF}
                    author={value.autor}
                    images={bilder}
                    text={value.news_text}
                />
            )
        };
        return (
            <React.Fragment>
                <div>

                </div>
                <div className="sep">
                    <div className="title">Instagram</div>
                </div>
                <div>

                </div>
                {artikel}
            </React.Fragment>
        );
    }
}

export default InstaFeed;