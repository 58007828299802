import React, { Component } from 'react';


import { Link } from 'react-router-dom'


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faBars } from '@fortawesome/free-solid-svg-icons';
import Logo from '../images/LogoHollenTINTE.png';
import Logo2 from '../images/logoUplengen.png';


library.add(faSortDown, faBars);

class Navbar extends Component {
    state = {
        collapseLink: "/mobileMenu" 
    }

    
    render() {
        return (
            <React.Fragment>
                <div className="rsc-navbar">
                    
                    <div className="nav-logo1">
                        <Link to="/">
                        <img src={Logo} alt="hollen" />
                        </Link>
                    </div>
                    <div className="nav-logo2">
                        <img src={Logo2} alt="uplengen" />
                    </div>
                    <ul className="rsc-navMenu">
                        <li className="navbar-item">
                            <div className="navbar-item-wrapper">
                                <div className="navbar-item-title">
                                    Hollen
                                </div>
                                <div className="navbar-item-dropdown-icon">
                                    <FontAwesomeIcon icon="sort-down" />
                                </div>
                            </div>
                            <ul>
                                <Link to="/"><li>Startseite</li></Link>
                                <Link to="/hollen"> <li>Das Dorf</li></Link>
                                <Link to="/hollen/ortsvorstand"> <li>Ortsvorstand</li></Link>
                                <Link to="/hollen/alte-schmiede"> <li>Alte Schmiede</li></Link>
                                <Link to="/hollen/dorfteich"> <li>Dorfteich</li></Link>
                                <Link to="/hollen/grundschule"> <li>Grundschule</li></Link>
                                <Link to="/hollen/kindergarten"> <li>Kindergarten</li></Link>
                                <li className="menu-divider"></li>
                                {/* <Link to="/hollen/geschichte"> <li>Geschichte</li></Link> */}
                                <Link to="/hollen/molkerei"> <li>Molkerei</li></Link>
                                <Link to="/hollen/muehle"> <li>Mühle</li></Link>
                            </ul>
                        </li>
                        <li className="navbar-item">
                            <div className="navbar-item-wrapper">
                                <div className="navbar-item-title">
                                    Aktuelles
                                </div>
                                <div className="navbar-item-dropdown-icon">
                                    <FontAwesomeIcon icon="sort-down" />
                                </div>
                            </div>

                            <ul>
                                <Link to="/news"><li>Aktuelles</li></Link>
                                <Link to="/events"> <li>Veranstaltungen</li></Link>
                            </ul>
                        </li>
                        <li className="navbar-item">
                            <div className="navbar-item-wrapper">
                                <div className="navbar-item-title">
                                    Urlaub
                                </div>
                                <div className="navbar-item-dropdown-icon">
                                    <FontAwesomeIcon icon="sort-down" />
                                </div>
                            </div>
                            <ul>
                                <Link to="/urlaub/karte"><li>Anreise & Karte</li></Link>
                                <Link to="/urlaub/unterkuenfte"><li>Unterkünfte</li></Link>
                                <li className="menu-divider"></li>
                                <Link to="/urlaub/galerie"><li>Galerie</li></Link>
                            </ul>
                        </li>
                        <li className="navbar-item">
                            <div className="navbar-item-wrapper">
                                <div className="navbar-item-title">
                                    Vereine etc.
                                </div>
                                <div className="navbar-item-dropdown-icon">
                                    <FontAwesomeIcon icon="sort-down" />
                                </div>
                            </div>
                            <ul>
                                <Link to="/vereine/uebersicht"><li>Übersicht</li></Link>

                                <li className="menu-divider"></li>
                                <Link to="/vereine/buergerverein"><li>Bürgerverein</li></Link>
                                <Link to="/vereine/da-capo"><li>Da Capo</li></Link>
                                <Link to="/vereine/foerderverein"><li>Förderverein</li></Link>
                                <Link to="/vereine/feuerwehr"><li>Feuerwehr</li></Link>
                                <Link to="/vereine/ftc-hollen"><li>FTC Hollen</li></Link>
                                <Link to="/vereine/gefluegelzuchtverein"><li>Geflügelzuchtverein</li></Link>
                                <Link to="/vereine/kirche"><li>Kirche</li></Link>
{/*                                 <Link to="/vereine/schmiedekreis"><li>Schmiedekreis</li></Link> */}

                            </ul>
                        </li>
                    </ul>

                    <div className="navbar-collapsed">
                        <Link to={this.state.collapseLink} ><FontAwesomeIcon  icon="bars" /></Link>
                    </div>


                </div>

            </React.Fragment>
        );
    }
}

export default Navbar;