import React from "react";
import { Component } from 'react';
import { Chart } from "react-charts";


const defs = (
    <defs>
        <linearGradient id="0" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="lightblue" />
            <stop offset="100%" stopColor="blue" />
        </linearGradient>
        <linearGradient id="1" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="red" />
            <stop offset="100%" stopColor="red" />
        </linearGradient>
        <linearGradient id="2" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="orange" />
            <stop offset="100%" stopColor="orange" />
        </linearGradient>
        <linearGradient id="3" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="cyan" />
            <stop offset="100%" stopColor="cyan" />
        </linearGradient>
        <linearGradient id="4" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="purple" />
            <stop offset="100%" stopColor="purple" />
        </linearGradient>
        <linearGradient id="5" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="magenta" />
            <stop offset="100%" stopColor="magenta" />
        </linearGradient>
        <linearGradient id="6" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="rgb(136, 104, 82)" />
            <stop offset="100%" stopColor="rgb(136, 104, 82)" />
        </linearGradient>
        <linearGradient id="7" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="black" />
            <stop offset="100%" stopColor="black" />
        </linearGradient>
        <linearGradient id="8" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="yellow" />
            <stop offset="100%" stopColor="yellow" />
        </linearGradient>
        <linearGradient id="9" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="green" />
            <stop offset="100%" stopColor="green" />
        </linearGradient>
        <linearGradient id="10" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="rgb(179, 11, 11)" />
            <stop offset="100%" stopColor="rgb(179, 11, 11)" />
        </linearGradient>
        <linearGradient id="11" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="rgb(44, 176, 253)" />
            <stop offset="100%" stopColor="rgb(44, 176, 253)" />
        </linearGradient>
    </defs>
)

class PolitikChart extends Component {
    state = {}
    render() {
        
        return (
            <div
                style={{
                    width: "100%",
                    height: "300px",
                    color: '#fff'
                }}
            >
                        <Chart
                            data={[

                                {
                                    label: "DDP",
                                    data: [[1919, 43.5], [1924, 17.1], [1928, 22.3]]
                                },
                                {
                                    label: "SPD",
                                    data: [[1919, 26.5], [1924, 5.7], [1928, 0], [1930, 0], [1932, 0], [1933, 0], [1949, 0], [1953, 0], [1957, 0], [1961, 14], [1965, 13.3], [1969, 0], [1972, 35.3], [2002, 43.9], [2005, 40.8], [2009, 24.9], [2013, 27.1], [2017, 25.2]]
                                },
                                {
                                    label: "DVP",
                                    data: [[1919, 29.6], [1924, 21.2], [1928, 11.2], [1930, 10.4]]
                                },
                                {
                                    label: "DNVP",
                                    data: [[1924, 53.5], [1928, 8.4], [1930, 13.3]]
                                },
                                {
                                    label: "VSB1",
                                    data: [[1928, 49.7]]
                                },
                                {
                                    label: "CSV",
                                    data: [[1930, 35.3]]
                                },
                                {
                                    label: "NSDAP",
                                    data: [[1930, 20.9], [1932, 89.4], [1933, 90.4]]
                                },
                                {
                                    label: "CDU",
                                    data: [[1949, 60.4], [1953, 64.5], [1957, 74.2], [1961, 67.7], [1965, 77.6], [1969, 66.7], [1972, 59], [2002, 41.6], [2005, 41.8], [2009, 45.6], [2013, 50.2], [2017, 47.2]]
                                },
                                {
                                    label: "FDP",
                                    data: [[1961, 14.3], [2002, 8.4], [2005, 8.1], [2009, 12], [2013, 5], [2017, 7.4]]
                                },
                                {
                                    label: "Grüne",
                                    data: [[2002, 3.9], [2005, 3.1], [2009, 6], [2013, 5.5], [2017, 4.3]]
                                },
                                {
                                    label: "Linke",
                                    data: [[2005, 3.4], [2009, 7.6], [2013, 4.7], [2017, 4.1]]
                                }
                                ,
                                {
                                    label: "AfD",
                                    data: [[2013, 3], [2017, 9.8]]
                                }
                            ]}
                            series={{ type: 'bar' }}
                            axes={[
                                {
                                    primary: true, position: 'bottom', type: 'ordinal'
                                },
                                { position: 'left', type: 'linear', stacked: true },
                            ]}
                            getSeriesStyle={series => ({
                                color: `url(#${series.index % 12})`,
                                
                            })}
                            renderSVG={() => defs}
                            primaryCursor
                            secondaryCursor
                            tooltip
                        />

            </div>
        );
    }
}

export default PolitikChart;