import React from 'react';
import News from '../react-components/News'
import InstaFeed from '../react-components/InstaFeed';
import Blog from '../react-components/Blog'

import { Helmet } from "react-helmet";

class Neuigkeiten extends React.Component {
    state = {}

    render() {
        return (
            <React.Fragment>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Neues aus Hollen</title>
                        <meta name="description" content="Aktuelle Meldungen aus Hollen." />
                        <link rel="canonical" href="https://hollen-uplengen.de/news" />
                    </Helmet>
                </div>
                <div className="rsc-content">
                    <Blog className="news-box" />

                </div>

            </React.Fragment>
        );
    }
}

export default Neuigkeiten;