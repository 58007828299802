import React, { Component } from 'react';

import { Link } from 'react-router-dom'


class MobileMenu extends Component {
    state = {}
    render() {
        
        return (
            <React.Fragment>
                <div className="mobile-menu-wrapper">
                    <ul>
                        <li className="mobile-navbar-item">
                            <div className="navbar-item-title">
                                Hollen
                                </div>
                            <ul>
                                <Link to="/"><li>Startseite</li></Link>
                                <Link to="/hollen"> <li>Das Dorf</li></Link>
                                <Link to="/hollen/ortsvorstand"> <li>Ortsvorstand</li></Link>
                                <Link to="/hollen/alte-schmiede"> <li>Alte Schmiede</li></Link>
                                <Link to="/hollen/dorfteich"> <li>Dorfteich</li></Link>
                                <Link to="/hollen/grundschule"> <li>Grundschule</li></Link>
                                <Link to="/hollen/kindergarten"> <li>Kindergarten</li></Link>
                                <Link to="/hollen/molkerei"> <li>Molkerei</li></Link>
                                <Link to="/hollen/muehle"> <li>Mühle</li></Link>
                            </ul>
                        </li>
                        <li className="mobile-navbar-item">

                            <div className="navbar-item-title">
                                Aktuelles
                                </div>


                            <ul>
                                <Link to="/news"><li>Aktuelles</li></Link>
                                <Link to="/aktuelles/veranstaltungen"> <li>Veranstaltungen</li></Link>
                            </ul>
                        </li>
                        <li className="mobile-navbar-item">

                            <div className="navbar-item-title">
                                Urlaub
                                </div>

                            <ul>
                                <Link to="/urlaub/karte"><li>Anreise & Karte</li></Link>
                                <Link to="/urlaub/unterkuenfte"><li>Unterkünfte</li></Link>
                                <Link to="/urlaub/galerie"><li>Galerie</li></Link>
                            </ul>
                        </li>
                        <li className="mobile-navbar-item">

                            <div className="navbar-item-title">
                                Vereine etc.
                                </div>
                            <ul>
                                <Link to="/vereine/uebersicht"><li>Übersicht</li></Link>
                                <li></li>
                                <Link to="/vereine/buergerverein"><li>Bürgerverein</li></Link>
                                <Link to="/vereine/da-capo"><li>Da Capo</li></Link>
                                <Link to="/vereine/foerderverein"><li>Förderverein</li></Link>
                                <Link to="/vereine/feuerwehr"><li>Feuerwehr</li></Link>
                                <Link to="/vereine/ftc-hollen"><li>FTC Hollen</li></Link>
                                <Link to="/vereine/gefluegelzuchtverein"><li>Geflügelzuchtverein</li></Link>
                                <Link to="/vereine/kirche"><li>Kirche</li></Link>

                            </ul>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default MobileMenu;