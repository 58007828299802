import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import SlideShow from '../react-components/SlideShow';
import Submenu from '../react-components/Submenu';
import SubmenuItem from '../react-components/SubmenuItem';
import KontaktKarte from '../react-components/KontaktKarte';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faLeaf, faChild, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import butter from '../butter-client'
import ModalImage from "react-modal-image";
var parse = require('html-react-parser');


library.add(faHome);
library.add(faChild);
library.add(faLeaf);

library.add(faPhone);
library.add(faEnvelope);



class Dorfteich extends Component {
    state = {
        data: {
            fields: {
                bilder: [],
                ansprechpartner: []
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'dorfteich')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data;
        const bilderz = [];
        const ansprechPartner = [];
        if (fields.text != null && fields.text !== "") {
            var parsedText = parse(fields.text);
        }


        for (const [index, value] of fields.bilder.entries()) {
            bilderz.push(
                <Carousel.Item key={index}>
                    <ModalImage
                        className="d-block w-100 center"
                        small={value.bild}
                        large={value.bild}
                        alt="Dorfteich"
                    />
                </Carousel.Item>
            )
        };
        for (const [index, value2] of fields.ansprechpartner.entries()) {
            ansprechPartner.push(
                <KontaktKarte key={index} image={value2.bild} name={value2.name} position={value2.position} strasse={value2.strasse} ort={value2.ort} phone={value2.telefon} mail={value2.email} />
            )
        };
        return (
            <React.Fragment>
                <div className="rsc-seperator">
                    <div className="title">Dorfteich</div>
                </div>


                <div className="leeresPanel">
                    <div width="100%">
                        <SlideShow width="100%">
                            {bilderz}
                        </SlideShow>
                        <Submenu>
                            <SubmenuItem>
                                <p className="submenu-icon"><FontAwesomeIcon icon="home" /> </p>
                                <p className="submenu-header">Remise</p>
                                <p className="submenu-text">Veranstaltungsort für Gruppen und <br />Grüppchen aller Art</p>
                            </SubmenuItem>
                            <SubmenuItem>
                                <p className="submenu-icon"><FontAwesomeIcon icon="child" /> </p>
                                <p className="submenu-header">Spielplatz</p>
                                <p className="submenu-text">Spielmöglichkeiten sind gegeben</p>
                            </SubmenuItem>
                            <SubmenuItem>
                                <p className="submenu-icon"><FontAwesomeIcon icon="leaf" /> </p>
                                <p className="submenu-header">Natur pur</p>
                                <p className="submenu-text">Der Dorfteich befindet sich mitten <br />im Grünen</p>
                            </SubmenuItem>
                        </Submenu>
                    </div>
                    <div className="textblock">
                        {parsedText}
                        {ansprechPartner}
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default Dorfteich;