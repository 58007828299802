import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import butter from '../butter-client'
import BlogPreview from './BlogPreview'

class App extends Component {
  state = {
    meta: {},
    data: []
  }
  async componentDidMount () {
    const { match } = this.props
    let page = 1;

    const resp = await butter.post.list({ page: page, page_size: 9 })
    this.setState(resp.data)
    console.log(resp.data)
  }
  render () {
    const { next_page, previous_page } = this.state.meta

    return (
            <React.Fragment>
         <div className="sep">
                <div className="title">Neues aus Hollen</div>
         </div>
        {this.state.data.map((post, key) => {
          console.log(post)
          return (
              <BlogPreview key={key} post={post} to={`/blog/posts/${post.slug}`} ></BlogPreview>
          )
        })}
        </React.Fragment>
    )
  }
}

export default App