import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faHome, faMapMarkerAlt, faArrowCircleRight, faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

library.add(faUser);
library.add(faHome);
library.add(faPhone);
library.add(faEnvelope);
library.add(faMapMarkerAlt);
library.add(faArrowCircleRight);
library.add(faGlobeEurope);



class VereineKarte extends Component {
    state = {}
    render() {
        var hasLogo = this.props.logo !== "";
        var hasPhone = this.props.telefon != null && this.props.telefon !== "";
        var hasMail = this.props.email != null && this.props.email !== "";
        var hasStrasse = this.props.strasse != null && this.props.strasse !== "";
        var hasOrt = this.props.ort != null && this.props.ort !== "";
        var hasText = this.props.text != null && this.props.text !== "";
        var hasLink = this.props.link != null && this.props.link !== "";
        var hasWebsite = this.props.website != null && this.props.website !== "";
        var hasBesitzer = this.props.besitzer != null && this.props.besitzer !== "";
        var linkTo = hasLink && this.props.link.includes("http") ? this.props.link : "/vereine/" + this.props.link;
        var extLink = hasLink && linkTo.includes("http");
        var parse = require('html-react-parser');
        if(hasText){
            var parsedText = parse(this.props.text); 
        }
        return (
            <React.Fragment>
                <div className="vereine-karte">
                    <div className="vereine-name">
                        {this.props.name}
                    </div>
                    {hasLogo &&
                        <div className="vereine-logo-container">
                            <img className="vereine-logo" alt="Bild" src={this.props.logo} />
                        </div>
                    }
                    <div className="vereine-anschrift">
                        {hasBesitzer &&
                            <div className="vereine-anschrift-item">
                                <FontAwesomeIcon className="kontakt-icon" icon="user" /> {this.props.besitzer}
                            </div>
                        }
                        {hasStrasse &&
                            <div className="vereine-anschrift-item">
                                <FontAwesomeIcon className="kontakt-icon" icon="home" /> {this.props.strasse}
                            </div>
                        }
                        {hasOrt &&
                            <div className="vereine-anschrift-item">
                                <FontAwesomeIcon className="kontakt-icon" icon="map-marker-alt" /> {this.props.ort}
                            </div>
                        }
                        {hasPhone &&
                            <div className="vereine-anschrift-item">
                            <FontAwesomeIcon icon="phone" className="kontakt-icon" /> <a href={"tel:" + this.props.telefon} alt="email">{this.props.telefon}</a>
                            </div>
                        }
                        {hasMail &&
                            <div className="vereine-anschrift-item">
                            <FontAwesomeIcon className="kontakt-icon" icon="envelope" /> <a href={"mailto:" + this.props.email} alt="email">{this.props.email}</a>
                            </div>
                        }
                        {hasWebsite &&
                            <div className="vereine-anschrift-item">
                            <FontAwesomeIcon className="kontakt-icon" icon="globe-europe" /><a target="_blank" rel="noopener noreferrer" href={this.props.website} alt="website">Webseite</a>
                            </div>
                        }
                    </div>
                    <br />
                    <div className="vereine-ansprechpartner-wrapper">
                        {this.props.ansprechPartner.length > 0 && 
                        <div className="vereine-ansprechpartner-header">
                            Kontakt
                        </div>
                        }
                        <div className="vereine-ansprechpartner">
                            {this.props.ansprechPartner}
                        </div>
                    </div>
                    {hasText &&
                        <div className="vereine-text">
                            {parsedText}
                        </div>
                    }
                    {hasLink &&
                        <div className="vereine-to-page">
                            {!extLink &&
                            <Link to={linkTo}><FontAwesomeIcon className="link-icon" icon="arrow-circle-right" /></Link>
                            }
                        {extLink &&
                            <a href={linkTo} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="link-icon" icon="arrow-circle-right" /></a>
                        }
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default VereineKarte;