import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faUser } from '@fortawesome/free-solid-svg-icons';
import { Carousel, CarouselItem } from 'react-bootstrap';
import ModalImage from "react-modal-image";

library.add(faCalendarDay);
library.add(faUser);

class NewsPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const bilders = [];

        const post = this.props.post;


        bilders.push(
            <CarouselItem key={1}>
                <img
                    className="news-image"
                    src={post.featured_image}
                    alt={post.featured_image_alt}
                />
            </CarouselItem>
        )
        var div = document.createElement('div');
        div.innerHTML = post.body;
        let keyCount = 2;
        for (let image of div.getElementsByTagName('img')) {
            // or, if you want the unresolved src, as it appears in the original HTML:
            var rawImgSrc = image ? image.getAttribute("src") : "";
            bilders.push(
                <CarouselItem key={keyCount++}>
                    <img
                        className="news-image"
                        src={rawImgSrc}
                        alt={post.featured_image_alt}
                    />
                </CarouselItem>
            )
        }



        const carouselVars = {
            indicators: false,
            controls: false
        }
        if (bilders.length > 1) {
            carouselVars.controls = true;
        }
        var options = {
            year: 'numeric', month: '2-digit', day: '2-digit'
        };
        var dateV = new Date(post.published);
        var dateF = dateV.toLocaleDateString("de-DE", options);



        return (
            <React.Fragment>
                    <a className="news-karte" href={this.props.to} rel="noopener noreferrer">
                        <div className="news-zeile">
                            <div className="news-author"><FontAwesomeIcon className="news-icon" icon="user" />{post.author.first_name}</div>
                            <div className="news-date"><FontAwesomeIcon className="news-icon" icon="calendar-day" />{dateF}</div>
                        </div>
                        <div className="news-image-container">
                            <Carousel className="news-slide" indicators={carouselVars.indicators} controls={carouselVars.controls}>
                                {bilders}
                            </Carousel>
                        </div>
                        <div className="news-title">
                            {post.title}
                        </div>
                        <div className="news-text">
                            {post.summary}
                        </div>
                    </a>
            </React.Fragment>
        );
    }
}

export default NewsPreview;