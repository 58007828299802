import React, { Component } from 'react';
import butter from '../butter-client'

class Datenschutz extends Component {
    state = {
        data: {
            fields: {
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'datenschutz')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data;
        var parse = require('html-react-parser');
        if (fields.flietext !== "" && fields.flietext != null) {
            var parsedText = parse(fields.flietext);
        }
        const absaetze = [];

        absaetze.push(
            <div className="home-text">
                {parsedText}
            </div>
        )
        return (
            <React.Fragment>
                <div className="rsc-seperator" >
                    <div className="title">{fields.headline}</div>
                    <div className="text">{fields.headline_untertitel}</div>
                </div>
                <div className="leeresPanel">
                    {absaetze}
                </div>
            </React.Fragment>
        );
    }
}

export default Datenschutz;