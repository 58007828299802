import React, { Component } from 'react';
import { Map, Marker } from 'react-map-gl';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHome, faStethoscope } from '@fortawesome/free-solid-svg-icons';
import ftcLOGO from '../images/wappen-ftc.png';
import ffw from '../images/ffw.png';
import leuchters from '../images/leuchters.png';
import tennis from '../images/tennis.png';

library.add(faHome);
library.add(faShoppingCart);
library.add(faStethoscope);

class Anreise extends Component {
    state = {
        viewport: {
            width: "100%",
            height: "90vh",
            latitude: 53.252896,
            longitude: 7.708676, 
            zoom: 15,
            controls: true
        }
    };
    render() {
        const TOKEN = "pk.eyJ1Ijoic2NobGllcHJvbiIsImEiOiJjanhuNWZpZmkwMHAxM2VubDBjdnAzb2p0In0.aZrICPGwkkN0xInRKsxnDg"
        var showMarkers = this.state.viewport.zoom > 14;
        return (
            <React.Fragment>
                <div className="map-container">
                    <Map
                        {...this.state.viewport}
                        onViewportChange={(viewport) => this.setState({ viewport })}
                        mapStyle='mapbox://styles/mapbox/streets-v11'
                    >
                        {showMarkers &&
                            <Marker latitude={53.252708} longitude={7.704650} offsetLeft={-20} offsetTop={-10}>
                                <div className="map-marker rsc-blue">
                                    <FontAwesomeIcon icon="shopping-cart" className="icon center" />
                                    <div className="text">Nah & Gut</div>
                                </div>
                            </Marker>
                        }
                        {showMarkers &&
                            <Marker latitude={53.251759} longitude={7.700160} offsetLeft={-30} offsetTop={0}>
                                <div className="map-marker">
                                    <FontAwesomeIcon icon="home" className="icon center" />
                                    <div className="text">Remise <br /> am Dorfteich</div>
                                </div>
                            </Marker>
                        }
                        {showMarkers &&
                            <Marker latitude={53.252341} longitude={7.701732} offsetLeft={0} offsetTop={0}>
                                <div className="map-marker">
                                    <FontAwesomeIcon icon="home" className="icon center" />
                                </div>
                            </Marker>
                        }
                        {showMarkers &&
                            <Marker latitude={53.254215} longitude={7.699458} offsetLeft={0} offsetTop={0}>
                                <div className="map-marker">
                                    <FontAwesomeIcon icon="home" className="icon center" />
                                </div>
                            </Marker>
                        }
                        {showMarkers &&
                            <Marker latitude={53.253952} longitude={7.699257} offsetLeft={0} offsetTop={0}>
                                <div className="map-marker">
                                    <img src={tennis} alt="bild" className="logo center"></img>
                                </div>
                            </Marker>
                        }
                        {showMarkers &&
                            <Marker latitude={53.254105} longitude={7.701102} offsetLeft={-30} offsetTop={-40}>
                                <div className="map-marker">
                                    <img src={ftcLOGO} alt="bild" className="logo center"></img>
                                    <div className="text">FTC Hollen</div>
                                </div>
                            </Marker>
                        }
                        {showMarkers &&
                            <Marker latitude={53.254462} longitude={7.702820} offsetLeft={-70} offsetTop={-30}>
                                <div className="map-marker">
                                    <img src={ffw} alt="bild" className="logo center"></img>
                                    <div className="text">Freiwillige Feuerwehr Uplengen Süd</div>
                                </div>
                            </Marker>
                        }
                        {showMarkers &&
                            <Marker latitude={53.255554} longitude={7.709912} offsetLeft={-20} offsetTop={0}>
                                <div className="map-marker">
                                    <img src={leuchters} alt="bild" className="logo center"></img>
                                    <div className="text">Frank Leuchters</div>
                                </div>
                            </Marker>
                        }
                        {showMarkers &&
                            <Marker latitude={53.252515} longitude={7.704014} offsetLeft={-60} offsetTop={0}>
                                <div className="map-marker rsc-rose">
                                    <FontAwesomeIcon icon="stethoscope" className="icon center" />
                                    <div className="text">Dr. med. Andreas Lindenthal</div>
                                </div>
                            </Marker>
                        }

                    </Map>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/dir//Hollener+Landstra%C3%9Fe,+26670+Uplengen/@53.2542149,7.7091626,18.13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47b6562350831537:0xa7e7e60b48515952!2m2!1d7.7101878!2d53.2544721" alt="Route planen">
                        <div className="linkbox-extern link-button">
                            Routenplaner (Google)
                        </div>
                    </a>
                </div>
            </React.Fragment >
        );
    }
}

export default Anreise;