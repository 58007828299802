import React, { Component } from 'react';

import NewsPreview from './NewsPreview';
import butter from '../butter-client'

class News extends Component {
    state = {
        data: {
            fields: {
                news_artikel: []
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'news')
        this.setState(resp.data)

        console.log(this.state)

    }
    render() {
        const artikel = [];
        const { fields } = this.state.data
        var options = {
            year: 'numeric', month: '2-digit', day: '2-digit'
        };

        for (const [index, value] of fields.news_artikel.entries()) {
            var dateV = new Date(value.datum);
            var dateF = dateV.toLocaleDateString("de-DE", options);
            const bilder = [
                value.bild
            ];
            if (value.bild2 !== "") {
                bilder.push(value.bild2);
            }
            if (value.bild3 !== "") {
                bilder.push(value.bild3);
            }
            if (value.bild4 !== "") {
                bilder.push(value.bild4);
            }
            if (value.bild5 !== "") {
                bilder.push(value.bild5);
            }
            artikel.push(
                <NewsPreview
                    key={index}
                    title={value.titel}
                    date={dateF}
                    author={value.autor}
                    images={bilder}
                    text={value.news_text}
                />
            )
        };
        return (
            <React.Fragment>

                <div className="sep">
                    <div className="title">Neues aus Hollen</div>
                </div>
                {artikel}
            </React.Fragment>
        );
    }
}

export default News;