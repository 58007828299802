import React from "react";
import { Component } from 'react';
import { Chart } from "react-charts";


class BevoelkerungChart extends Component {
    state = {}
    render() {
        const defs = (
            <defs>
                
            </defs>
        )

        return (
            <div
                style={{
                    width: "100%",
                    height: "300px",
                    color: '#fff'
                }}
            >
                <Chart
                    data={[
                        {
                            label: "Einwohner",
                            data: [[1821, 266], [1848, 379], [1871, 452], [1885, 498], [1905, 565], [1925, 605], [1933, 635], [1946, 961], [1950, 913], [1956, 778], [1961, 760], [1970, 846], [2017, 1201]]
                        }                    
                    ]}
                    series={{ type: 'area' }}
                    axes={[
                        {
                            primary: true, position: 'bottom', type: 'ordinal' },
                        { position: 'left', type: 'linear', stacked: true },
                    ]} getSeriesStyle={series => ({
                        color: `url(#${series.index % 1})`,

                    })}
                    renderSVG={() => defs}
                    primaryCursor
                    secondaryCursor
                    tooltip
                />

                </div>
        );
    }
}

export default BevoelkerungChart;
