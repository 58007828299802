import React from 'react';
import butter from '../butter-client';
import News from '../react-components/News';

import { Helmet } from "react-helmet";
import Blog from '../react-components/Blog'

class Home extends React.Component {
    state = {
        data: {
            fields: {
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'home')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data
        var parse = require('html-react-parser');
        if (fields.text_startseite !== "" && fields.text_startseite != null) {
            var parsedText = parse(fields.text_startseite);
        }
        return (
            <React.Fragment>

                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={fields.seo_text} />
                    <title>Hollen - daar sitt Leven in!</title>
                    <link rel="canonical" href="http://hollen.de/" />
                </Helmet>
                
                <div className="rsc-content news-box">

                    <Blog className="news-box" />

                </div>

            </React.Fragment>
        );
    }
}

export default Home;