import React from 'react';
import Footer from '../react-components/Footer';
import Navbar from '../react-components/Navbar';
import CookieConsent from "react-cookie-consent";


class Root extends React.Component {

    state = {}

    render() {
        return (

            <div className="rsc-appWrapperHome ">
                <Navbar ref="navbar">

                </Navbar>
                {this.props.children}
                <CookieConsent buttonText="Okay">
                    Diese Webseite nutzt Cookies um die Nutzererfahrung zu verbessern.
                </CookieConsent>    
                <Footer />
            </div>
        );
    }
}

export default Root;