import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faHome);
library.add(faPhone);
library.add(faEnvelope);
library.add(faMapMarkerAlt);


var hasImage = false;
var hasName = false;
var hasPhone = false;
var hasMail = false;
var hasStrasse = false;
var hasOrt = false;
var hasPosition= false;



class KontaktKarte extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        hasImage = this.props.image != null && this.props.image !== "";
        hasName = this.props.name != null && this.props.name !== "";
        hasPosition= this.props.position != null && this.props.position !== "";
        hasPhone = this.props.phone != null && this.props.phone !== "";
        hasMail = this.props.mail != null && this.props.mail !== "";
        hasStrasse = this.props.strasse != null && this.props.strasse !== "";
        hasOrt = this.props.ort != null && this.props.ort !== "";

    }

    render() {
        return (
            <React.Fragment>
                <div className="kontakt-karte">
                    {hasImage &&
                        <img
                            className="d-block kontakt-image"
                            src={this.props.image}
                            alt="Dorfteich"
                        />
                    }
                    <div>
                        {hasName &&
                            <div className="kontakt-name">
                                {this.props.name}
                            </div>
                        }
                        {hasPosition &&
                            <div className="kontakt-position">
                                {this.props.position}
                            </div>
                        }
                        {hasStrasse &&
                            <div className="kontakt-zeile">
                                <FontAwesomeIcon className="kontakt-icon" icon="home" /><span className="kontakt-text">{this.props.strasse}</span>
                            </div>
                        }
                        {hasOrt &&
                            <div className="kontakt-zeile">
                                <FontAwesomeIcon className="kontakt-icon" icon="map-marker-alt" /><span className="kontakt-text">{this.props.ort}</span>
                            </div>
                        }
                        {hasPhone &&
                            <div className="kontakt-zeile">
                            <FontAwesomeIcon icon="phone" className="kontakt-icon" /> <a href={"tel:" + this.props.phone} alt="email">{this.props.phone}</a>
                            </div>
                        }
                        {hasMail &&
                            <div className="kontakt-zeile">
                                <FontAwesomeIcon className="kontakt-icon" icon="envelope" /> <a href={"mailto:" + this.props.mail} alt="email">{this.props.mail}</a>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default KontaktKarte;