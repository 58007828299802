import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Footer extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="footer-wrapper">
                    <div className="rsc-footer">
                        <div className="rsc-footer-column">
                            <Link className="rsc-footer-item" to="/impressum">Impressum</Link>
                            <Link className="rsc-footer-item" to="/datenschutz">Datenschutz</Link>
                            <p>©Ron Schliep 2022</p>
                        </div>

                        <div className="butterCMS">
                            powered by <a href="https://buttercms.com" ><img src="https://cdn.buttercms.com/JSSDbrHPSnGlLUcyHTn5" alt="ButterCMS" /></a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Footer;