import React, { Component } from 'react';


class Submenu extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="submenu-container">
                {this.props.children}
            </div>

         );
    }
}
 
export default Submenu;