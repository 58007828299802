import React from 'react';
import { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import SlideShow from '../react-components/SlideShow';
import butter from '../butter-client'
var parse = require('html-react-parser');


class Muehle extends Component {
    state = {
        data: {
            fields: {
                bilder: []
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'muehle')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data;
        const bilderz = [];
        if (fields.text != null && fields.text !== "") {
            var parsedText = parse(fields.text);
        }


        for (const [index, value] of fields.bilder.entries()) {
            bilderz.push(
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-70 center"
                        src={value.bild}
                        alt="Muehle"
                    />
                </Carousel.Item>
            )
        };
        return (
            <React.Fragment>
                <div className="rsc-seperator">
                    <div className="title">Mühle</div>
                </div>

                <div className="leeresPanel">
                    <div width="100%">
                        <SlideShow width="100%">
                            {bilderz}
                        </SlideShow>
                    </div>
                    <div className="textblock">
                        {parsedText}
                    </div>
                </div>

            </React.Fragment >
        );
    }
}

export default Muehle;