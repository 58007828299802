import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faEuroSign, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faCalendarDay);
library.add(faEuroSign);
library.add(faMapMarkerAlt);


class VeranstaltungsKarte extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        var hasEintritt = this.props.eintritt !== "";
        return (
            <React.Fragment>
                <div className="termine-karte">
                    <div className="termine-zeile">
                        <div className="termine-date"><FontAwesomeIcon className="news-icon" icon="calendar-day" />{this.props.date}</div>
                        {hasEintritt &&
                            <div className="termine-eintritt">{this.props.eintritt} <FontAwesomeIcon className="news-icon" icon="euro-sign" /></div>
                        }
                    </div>
                    <div className="termine-image-container">
                        <img
                            className="termine-image"
                            src={this.props.image}
                            alt="Bild"
                        />
                    </div>
                    <div className="termine-title">
                        {this.props.title}
                    </div>
                    <div className="termine-text">
                        {this.props.text}
                    </div>
                    <div className="termine-ort">
                        <FontAwesomeIcon className="news-icon" icon="map-marker-alt" /> {this.props.ort}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default VeranstaltungsKarte;