import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import butter from '../butter-client';


class Galerie extends Component {

    state = {
        data: {
            fields: {
                bilder: [
                    {
                        tags:[]
                    }
                ]
            }
        }
    }

    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'galerie')
        this.setState(resp.data)
    }

    render() {
        const { fields } = this.state.data;
        const IMAGES = [];
        for (const [index, value] of fields.bilder.entries()) {
            IMAGES.push(
                {
                    key: index,
                    src: value.bild,
                    thumbnail: value.bild,
                    thumbnailWidth: value.breite,
                    thumbnailHeight: value.hoehe,
                    caption: value.caption
                }
            )
        };
        return (
            <React.Fragment>
                <div className="rsc-seperator">
                    <div className="title">Galerie</div>
                </div>
                <div className="leeresPanel">
                    <Gallery images={IMAGES} />
                </div>
            </React.Fragment>
        );
    }
}

export default Galerie;