import React, { Component } from 'react';
import butter from '../butter-client';
import KontaktKarte from '../react-components/KontaktKarte';

import VereineKarte from '../react-components/VereineKarte';

class GenericInfoPage extends Component {
    state = {
        data: {
            fields: {
                einrichtung: [
                    {
                        ansprechpartner: []
                    }
                ]
            }
        }
    }

    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'bildung')
        this.setState(resp.data)
    }

    render() {
        const { fields } = this.state.data;
        const vereine = [];
        const { match: { params } } = this.props;
        for (const [index, value] of fields.einrichtung.entries()) {
            if (value.link === params.ID) {

                var ansprechPartner = [];
                for (const [index, value2] of value.ansprechpartner.entries()) {
                    ansprechPartner.push(
                        <KontaktKarte key={index} position={value2.position} image={value2.bild} name={value2.name} strasse={value2.strasse} ort={value2.ort} phone={value2.telefon} mail={value2.email} />
                    )
                }
                vereine.push(
                    <VereineKarte key={index} name={value.name} logo={value.logo} ort={value.ort} strasse={value.strasse} telefon={value.telefon} email={value.email} website={value.website} text={value.text} ansprechPartner={ansprechPartner} />
                )
            }
        };
        return (
            <React.Fragment>
                <div className="leeresPanel">
                    {vereine}
                </div>
            </React.Fragment>
        );
    }
}

export default GenericInfoPage;