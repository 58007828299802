import React from 'react';
import './App.css';
import Root from './pages/Root';
import Home from './pages/Home';
import Ortsvorstand from './pages/Ortsvorstand';
import Geschichte from './pages/Geschichte';
import { Switch, Route } from 'react-router-dom'
import Molkerei from './pages/Molkerei';
import Schmiede from './pages/Schmiede';
import Dorfteich from './pages/Dorfteich';
import Veranstaltungen from './pages/Veranstaltungen';
import Neuigkeiten from './pages/Neuigkeiten';
import DasDorf from './pages/DasDorf';
import Menu from './pages/MobileMenu';


import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'
import VereineUebersicht from './pages/VereineUebersicht';
import VereinPage from './pages/VereinPage';
import GenericInfoPage from './pages/GenericInfoPage';
import UnterkuenfteUebersicht from './pages/UnterkuenfteUebersicht';
import Galerie from './pages/Galerie';
import Muehle from './pages/Muehle';
import Anreise from './pages/Anreise';
import Impressum from './pages/Impessum';
import Datenschutz from './pages/Datenschutz';
import Blog from './react-components/Blog'
import BlogPost from './react-components/BlogPost'



library.add(faStroopwafel)

function App() {

  return (

    <Root>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path={"/home"} component={Home} />
        <Route exact path={"/hollen"} component={DasDorf} />
        <Route path={"/aktuelles/neuigkeiten"} component={Neuigkeiten} />
        <Route path={"/aktuelles/veranstaltungen"} component={Veranstaltungen} />
        <Route path={"/news"} component={Neuigkeiten} />
        <Route path={"/events"} component={Veranstaltungen} />
        <Route path={"/hollen/ortsvorstand"} component={Ortsvorstand} />
        {/* <Route path={"/hollen/geschichte"} component={Geschichte} /> */}
        <Route path={"/hollen/molkerei"} component={Molkerei} />
        <Route path={"/hollen/muehle"} component={Muehle} />
        <Route exact path={"/hollen/alte-schmiede"} component={Schmiede} />
        <Route path={"/hollen/dorfteich"} component={Dorfteich} />
        <Route path={"/hollen/:ID"} component={GenericInfoPage} />
        <Route path={"/vereine/uebersicht"} component={VereineUebersicht} />
        <Route path={"/vereine/:vereinID"} component={VereinPage} />
        <Route path={"/urlaub/unterkuenfte"} component={UnterkuenfteUebersicht} />
        <Route path={"/urlaub/galerie"} component={Galerie} />
        <Route path={"/urlaub/karte"} component={Anreise} />
        <Route path={"/mobileMenu"} component={Menu} />
        <Route path={"/impressum"} component={Impressum} />
        <Route path={"/datenschutz"} component={Datenschutz} />

        <Route path='/blog/:page' exact component={Blog} />
        <Route path='/blog' exact component={Blog} />
        <Route path='/blog/posts/:post' component={BlogPost} />

      </Switch>
    </Root>
  );
}

export default App;
