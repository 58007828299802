import React, { Component } from 'react';
import butter from '../butter-client';
import KontaktKarte from '../react-components/KontaktKarte';

import VereineKarte from '../react-components/VereineKarte';


class VereinPage extends Component {
    state = {
        data: {
            fields: {
                verein: [
                    {
                        ansprechpartner: []
                    }
                ]
            }
        }
    }

    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'vereine')
        this.setState(resp.data)
    }

    render() {

        var notFound = "";
        const { fields } = this.state.data;
        const vereine = [];
        const { match: { params } } = this.props;
        var noSuchClub = false;
        var found = false;
        for (const [index, value] of fields.verein.entries()) {
            if (value.link === params.vereinID) {
                found = true;
                var ansprechPartner = [];
                for (const [index, value2] of value.ansprechpartner.entries()) {
                    ansprechPartner.push(
                        <KontaktKarte key={index} image={value2.bild} name={value2.name} position={value2.position} strasse={value2.strasse} ort={value2.ort} phone={value2.telefon} mail={value2.email} />
                    )
                }
                vereine.push(
                    <VereineKarte key={index} name={value.name} logo={value.logo} ort={value.ort} strasse={value.strasse} telefon={value.telefon} email={value.email} website={value.website} text={value.text} ansprechPartner={ansprechPartner} />
                )
            }
        };
        if(!found){
            noSuchClub = true;
        }
        return (
            <React.Fragment>
                <div className="leeresPanel">
                    {vereine}
                    {noSuchClub && 
                        <div className="not-found-text">{notFound}</div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default VereinPage;