import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faUser } from '@fortawesome/free-solid-svg-icons';
import { Carousel, CarouselItem } from 'react-bootstrap';
import ModalImage from "react-modal-image";

library.add(faCalendarDay);
library.add(faUser);

class NewsPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const bilders = [];
        for (const [index, value] of this.props.images.entries()) {
            bilders.push(
                <CarouselItem key={index}>
                    <ModalImage
                        className="news-image"
                        small={value}
                        large={value}
                        alt="Bild"
                    />
                </CarouselItem>
            )
        };
        const carouselVars = {
            indicators: false,
            controls: false
        }
        if (bilders.length > 1) {
            carouselVars.controls = true;
        }
        return (
            <React.Fragment>
                <div className="news-karte">
                    <div className="news-zeile">
                        <div className="news-author"><FontAwesomeIcon className="news-icon" icon="user" />{this.props.author}</div>
                        <div className="news-date"><FontAwesomeIcon className="news-icon" icon="calendar-day" />{this.props.date}</div>
                    </div>
                    <div className="news-image-container">
                        <Carousel className="news-slide" indicators={carouselVars.indicators} controls={carouselVars.controls}>
                            {bilders}
                        </Carousel>
                    </div>
                    <div className="news-title">
                        {this.props.title}
                    </div>
                    <div className="news-text">
                        {this.props.text}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default NewsPreview;