import React, { Component } from 'react';
import butter from '../butter-client'
import KontaktKarte from '../react-components/KontaktKarte'
import VereineKarte from '../react-components/VereineKarte';

import { Helmet } from "react-helmet";

class VereineUebersicht extends Component {
    state = {
        data: {
            fields: {
                verein: [
                    {
                        ansprechpartner: []
                    }
                ]
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'vereine')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data;
        const vereine = [];
        for (const [index, value] of fields.verein.entries()) {
            var ansprechPartner = [];
            for (const [index, value2] of value.ansprechpartner.entries()) {
                ansprechPartner.push(
                    <KontaktKarte key={index} image={value2.bild} name={value2.name} position={value2.position} strasse={value2.strasse} ort={value2.ort} phone={value2.telefon} mail={value2.email} />
                )
            }
            vereine.push(
                <VereineKarte key={index} name={value.name} logo={value.logo} ort={value.ort} strasse={value.strasse} telefon={value.telefon} email={value.email} website={value.website} link={value.link} ansprechPartner={ansprechPartner} />
            )
        };
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Vereine</title>
                    <meta name="description" content="Erfahre hier welche Vereine es in Hollen gibt." />
                    <link rel="canonical" href="http://hollen.de/vereine/uebersicht" />
                </Helmet>
                <div className="leeresPanel">
                    {vereine}
                </div>
            </React.Fragment>
        );
    }
}

export default VereineUebersicht;