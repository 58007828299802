import React, { Component } from 'react';
import butter from '../butter-client';
import VereineKarte from '../react-components/VereineKarte';

class UnterkuenfteUebersicht extends Component {
    state = {
        data: {
            fields: {
                unterkunft: []
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'unterkuenfte')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data;
        const unterkunft = [];
        for (const [index, value] of fields.unterkunft.entries()) {
            var ansprechPartner = [];
            unterkunft.push(
                <VereineKarte key={index} name={value.name} besitzer={value.besitzer} logo={value.logo} ort={value.ort} strasse={value.strasse} telefon={value.telefon} email={value.email} website={value.website} link={value.link} ansprechPartner={ansprechPartner} />
            )
        };
        return (
            <React.Fragment>
                <div className="rsc-seperator" >
                    <div className="title">Privatunterkünfte</div>
                </div>
                <div className="leeresPanel">
                    {unterkunft}
                </div>
            </React.Fragment>
        );
    }
}

export default UnterkuenfteUebersicht;