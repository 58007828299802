import React from 'react';
import KontaktKarte from '../react-components/KontaktKarte';

import butter from '../butter-client'
var parse = require('html-react-parser');

class Ortsvorstand extends React.Component {
    state = {
        data: {
            fields: {
                vorstand: []
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'ortsvorstand')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data;
        const ansprechPartner = [];
        if (fields.text != null && fields.text !== "") {
            var parsedText = parse(fields.text);
        }
        for (const [index, value2] of fields.vorstand.entries()) {
            ansprechPartner.push(
                <div className="rsc-vorstand-card">
                    <KontaktKarte key={index} image={value2.bild} name={value2.name} position={value2.position} strasse={value2.strasse} ort={value2.ort} phone={value2.telefon} mail={value2.email} />
                </div>
            )
        };
        return (
            <jsxFragment>
                <div className="leeresPanel">
                    <div className="rsc-seperator">
                        <div className="title">Ortsvorstand</div>
                        <div className="text">{parsedText}</div>
                    </div>
                    <div className="rsc-vorstand">
                        {ansprechPartner}                    
                    </div>
                </div>
            </jsxFragment>
        );
    }
}

export default Ortsvorstand;