import React from 'react';
import Timeline from 'react-dual-timeline'
import { StyleRoot } from 'radium'
import BevoelkerungChart from '../react-components/BevoelkerungChart';
import PolitikChart from '../react-components/PolitikChart';

import butter from '../butter-client'
var parse = require('html-react-parser');


class Geschichte extends React.Component {
    state = {
        data: {
            fields: {
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'geschichte')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data;

        if (fields.text != null && fields.text !== "") {
            var parsedText = parse(fields.text);
        }

        return (
            <React.Fragment>
                <StyleRoot>
                        <div className="rsc-seperator">
                            <div className="title">Geschichte</div>
                            <div className="text"></div>
                        </div>
                    <div className="geschichtePanel">
                        <div className="textblock">
                            <div className="header">
                                Das tausendjährige Hollen
                            </div>
                            <p>
                                Schon im 9./10. Jh. wird Hollen als "Holanlae" in den Urbaren des Klosters aufgeführt. Zusammen mit Selverde ist es der am frühesten schriftlich erwähnte Ort der Einheitsgemeinde Uplengen. Es war mit weiteren in dieser Quelle genannten Ortschaften dieser Region Ostfrieslands von dem Friesenmissionar Liudger gegründeten Kloster bei Essen zur Zahlung bestimmter Abgaben verpflichtet. Man mag daraus den Rückschluss ziehen, dass es sich bei diesem Gebiet um das zur Zeit Karls des Großen von Liudger selbst christianisierte Missionsgebiet handelt.
                            </p>
                            <div className="header">
                                Geschichte Hollens
                            </div>
                            <Timeline activeColor='rgba(29, 102, 151, 0.657)' animations={false} lineColor='rgba(58, 146, 70, 0.767)' color='#fff'>
                                <div><h1>nach 1775</h1>
                                    <p>
                                        Entstehung von Bargerfehn und Brückenfehn
                                    </p>
                                </div>
                                <div>
                                    <h1>1807</h1>
                                    <p>
                                        entsteht eine Ansiedlung "über dem Tiefe" (= Klein-Hollen). Erster Siedler: Dirck Hinrichs Bruns
                                    </p>
                                </div>
                                <div>
                                    <h1>1809</h1>
                                    <p>
                                        hatte Hollen (mit Brückenfehn) 162 Einwohner
                                    </p>
                                </div>
                                <div>
                                    <h1>1820</h1>
                                    <p>
                                        Besiedlung des Wester=Felds, erster Siedler: Hermann Harberts, aus Nortmoor
                                    </p>
                                </div>
                                <div>
                                    <h1>1820</h1>
                                    <p>
                                        erster Siedler auf Zinsken=Fehn: Weert Folckers
                                    </p>
                                </div>
                                <div>
                                    <h1>1829</h1>
                                    <p>
                                        Gründung der beiden Fehnsiedlungen: Nordgeorgsfehn und Südgeorgsfehn
                                    </p>
                                </div>
                                <div>
                                    <h1>1963</h1>
                                    <p>
                                        Bildung der Samtgemeinde Hollen mit den Mitgliedsgemeinden Ammersum, Hollen, Lammertsfehn, Nordgeorgsfehn und Südgeorgsfehn
                                    </p>
                                </div>
                                <div>
                                    <h1>1.1.1973</h1>
                                    <p>
                                        Mit der Verwaltungs- und Gebietsreform wird die Samtgemeinde Hollen aufgelöst. Hollen, Nord- und Südgeorgsfehn werden Teile der Einheitsgemeinde Uplengen.
                                    </p>
                                </div>

                            </Timeline>
                        </div>
                        {parsedText}
                        <div className="textblock">
                            <div className="header">
                                Bevölkerungsentwicklung
                            </div>
                            <BevoelkerungChart />
                            <p>1946 wurden hier insgesamt 961 Einwohner verzeichnet, von denen 263 Personen Flüchtlinge waren, was einem Anteil von 27,4% entspricht. 1950 registrierte man 913 Einwohner. Die Zahl der Flüchtlinge lag bei 184. Die Quote sank somit deutlich auf 20,2%.</p>
                        </div>
                        <div className="textblock">
                            <div className="header">
                                Politische Orientierung und öffentliche Meinung
                            </div>
                            <PolitikChart />
                            <p>Bei der Wahl zur Nationalversammlung im Januar 1919 gewann die liberale DDP, die zu Beginn
                                der Weimarer Republik in vielen Gemeinden großen Zuspruch fand, mit 43,5%, gefolgt von der nationalliberalen DVP mit 29,6% und der SPD mit 26,5%.</p>
                            <p>Bei der Reichstagswahl 1924 siegte dann nach explosionsartigem Zugewinn die national- konservative DNVP mit der absoluten Mehrheit von 53,4%. Zweiter wurde trotz deutlicher Verluste wieder die DVP mit 21,2%. Die DDP stürzte erdrutschartig auf 17,1% ab. Auch die SPD verlor dramatisch und musste sich mit 5,7% bescheiden.</p>
                            <p>1928 triumphierte dann der VSB1 (Völkisch-Sozialer Block), eine Partei, die durch Zusammenschluss von DVFP (Deutschvölkische Freiheitspartei) und der NSDAP entstanden war, mit 49,7%. Die DDP legte wieder zu und wurde mit 22,3% Zweiter, vor der DVP mit nur noch 11,2% und der DNVP, die nach extremen Einbußen lediglich 8,4% erreichte.</p>
                            <p>Bei der Reichstagswahl 1930 gab es wieder einen anderen Sieger. Diesmal hatte der CSV mit
                                35,3% die Nase vorn. Darauf folgte nach deutlichem Zugewinn die rechtsextreme NSDAP mit
                                20,9%, vor der DNVP mit 13,3% und der DVP mit 10,4%.</p>
                            <p>Die Reichstagswahlen von 1932 und 1933 führten zu einem Machtmonopol der NSDAP, die nun
                                89,4 - bzw. 90,4% verzeichnen konnte. Alle anderen Parteien wurden quasi zu Statisten degradiert und spielten keine Rolle mehr.</p>
                            <p>Die Bundestagswahlen von 1949 bis 1972 dominierte die CDU mit überwältigenden absoluten
                                Mehrheiten von 60,4% (1949), 64,5% (1953), 74,2% (1957), 67,7% (1961), 77,6% (1965),
                                66,7% (1969) und 59% (1972). Die SPD hatte dem nur Ergebnisse zwischen 13,3% (1965) und
                                35,3% (1972) entgegenzusetzen und wurde jeweils (ausgenommen 1961) Zweiter. 1961 kam die FDP mit 14,3%, knapp vor der SPD mit 14%, auf die zweite Position. Die rechtsgerichtete DRP (Deutsche Reichspartei) erzielte 1949 mit 12,8% ihr bestes Resultat.</p>
                            <p>Die Bundstagswahlen ab 2002 brachten folgende Ergebnisse:<br />
                                2002: SPD 43,9%, CDU 41,6%, FDP 8,4%, Grüne 3,9%<br />
                                2005: SPD 40,8%, CDU 41,8%, FDP 8,1%, Grüne 3,1%, Die Linke 3,4%<br />
                                2009: SPD 24,9%, CDU 45,6%, FDP 12,0%, Grüne 6,0%, Die Linke 7,6%<br />
                                2013: SPD 27,1%, CDU 50,2%, FDP 5,0%, Grüne 5,5%, Die Linke 4,7%, AfD 3,0%<br />
                                2017: SPD 25,2%, CDU 47,2%, FDP 7,4%, Grüne 4,3%, Die Linke 4,1%, AfD 9,8%</p>
                        </div>


                    </div>
                </StyleRoot>
            </React.Fragment>
        );
    }
}

export default Geschichte;