import React from 'react'
import butter from '../butter-client'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import { Helmet } from 'react-helmet'

import { Carousel, CarouselItem } from 'react-bootstrap';
import ModalImage from "react-modal-image";


export default class extends React.Component {
  state = {
    data: {}
  }
  async componentDidMount() {
    const { match } = this.props
    const resp = await butter.post.retrieve(match.params.post)
    this.setState(resp.data)
  }

  getBilderFromPost(post) {
    let bilders = [];
    bilders.push(
      <CarouselItem key={1}>
        <ModalImage
          className="news-image"
          small={post.featured_image}
          large={post.featured_image}
          alt={post.featured_image_alt}
        />
      </CarouselItem>
    )
    var div = document.createElement('div');
    div.innerHTML = post.body;
    let keyCount = 2;
    for (let image of div.getElementsByTagName('img')) {
      // or, if you want the unresolved src, as it appears in the original HTML:
      var rawImgSrc = image ? image.getAttribute("src") : "";
      bilders.push(
        <CarouselItem key={keyCount++}>
          <ModalImage
            className="news-image"
            small={rawImgSrc}
            large={rawImgSrc}
            alt={post.featured_image_alt}
          />
        </CarouselItem>
      )
    }
    return bilders;
  }
  render() {
    const post = this.state.data

    const bilders = this.getBilderFromPost(post);

    console.log(bilders)
    const carouselVars = {
      indicators: false,
      controls: false
    }
    if (bilders.length > 1) {
      carouselVars.controls = true;
    }

    var div = document.createElement('div');
    div.innerHTML = post.body;
    div.querySelectorAll("img")
      .forEach(img => img.remove());
    console.log(bilders)
    var options = {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
    };
    var dateV = new Date(post.published);
    var dateF = dateV.toLocaleDateString("de-DE", options);

    var linkUrl = "https://hollen-uplengen.de/blog/posts/" + post.slug;
    return (

      <React.Fragment>
        <div className="blogPanel">
          <Helmet>
            <title>{post.seo_title}</title>
            <meta name='description' content={post.meta_description} />
            <meta name='og:image' content={post.featured_image} />
          </Helmet>
          <div className="blog-post-image-container">
            <Carousel className="news-slide" indicators={carouselVars.indicators} controls={carouselVars.controls}>
              {bilders}
            </Carousel>
          </div>

          <div className='blog-post-container'>
            <div className='blog-post-title'>{post.title}</div>
            <div className="blog-post-social-share" >
              <EmailShareButton url={linkUrl} subject={post.seo_title} body={post.summary}><EmailIcon size={32} round={true} /></EmailShareButton>
              <FacebookShareButton url={linkUrl} quote={post.summary} hashtag="hollen" ><FacebookIcon size={32} round={true} /></FacebookShareButton>
              <TwitterShareButton url={linkUrl} title={post.seo_title} ><TwitterIcon size={32} round={true} /></TwitterShareButton>
              <WhatsappShareButton url={linkUrl} title={post.seo_title}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
            </div>
            <div className='blog-post-stand'>{dateF} Uhr</div>
            <div className='blog-post-text' dangerouslySetInnerHTML={{ __html: div.innerHTML }} />
          </div></div>
      </React.Fragment>
    )
  }
}