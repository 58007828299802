import React, { Component } from 'react';
import butter from '../butter-client'

class DasDorf extends Component {
    state = {
        data: {
            fields: {
                absatz: []
            }
        }
    }
    async componentDidMount() {
        const resp = await butter.page.retrieve('*', 'hollen')
        this.setState(resp.data)
    }
    render() {
        const { fields } = this.state.data;
        const absaetze = [];
        for (const [index, value] of fields.absatz.entries()) {
            var hasImage = value.bild !== "";
            absaetze.push(
                <div key={index} className="home-text">
                    <div className="header center">
                        {value.titel}
                    </div>
                    {value.flietext}
                    <br />
                    {hasImage &&
                        <img className="generic-image" alt="Bild" src={value.bild} />
                    }
                </div>
            )
        };
        return (
            <React.Fragment>
                <div className="rsc-seperator" >
                    <div className="title">{fields.headline}</div>
                    <div className="text">{fields.headline_untertitel}</div>
                </div>
                <div className="leeresPanel">
                    {absaetze}
                </div>
            </React.Fragment>
        );
    }
}

export default DasDorf;